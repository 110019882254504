<template>
  <div style="padding: 16px">
    <!-- 头部部分 -->
    <div ref="search_d">
      <!-- <el-row type="flex" class="row-bg">

        <el-col :span="6">
          <el-input placeholder="请输入内容" v-model="queryInfo.condition.queryKey" class="input-with-select" clearable>
            <el-button slot="append" icon="el-icon-search" @click="getList"></el-button>
          </el-input>
        </el-col>
      </el-row> -->
      <el-form :inline="true">
        <el-form-item label="商家名称:">
          <el-input
            clearable
            v-model="queryInfo.condition.merchantName"
            style="width: 200px"
            placeholder="商家名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="联系电话:">
          <el-input
            clearable
            v-model="queryInfo.condition.phone"
            style="width: 200px"
            placeholder="联系电话"
          ></el-input>
        </el-form-item>
        <el-button type="primary" @click="getList">查询</el-button>
        <el-button @click="addTableData" icon="el-icon-plus"
          >店铺入驻</el-button
        >
      </el-form>
    </div>

    <!-- 表格部分 -->
    <div>
      <el-table
        border
        ref="table"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
        height="calc(100vh - 220px)"
      >
        <el-table-column
          label="序号"
          fixed="left"
          type="index"
          width="50"
          align="center"
        ></el-table-column>
        <el-table-column label="商家名称" fixed="left" align="center">
          <template slot-scope="scope">{{ scope.row.merchantName }}</template>
        </el-table-column>
        <el-table-column
          label="联系人姓名"
          align="center"
          show-overflow-tooltip
        >
          <template slot-scope="scope">{{ scope.row.linkMan }}</template>
        </el-table-column>
        <el-table-column
          min-width="120px"
          label="联系电话"
          align="center"
          show-overflow-tooltip
        >
          <template slot-scope="scope">{{ scope.row.phone }}</template>
        </el-table-column>
        <el-table-column label="营业执照号" align="center">
          <template slot-scope="scope">{{ scope.row.licenseNo }}</template>
        </el-table-column>
        <el-table-column min-width="160px" label="简介" align="center">
          <template slot-scope="scope">{{ scope.row.merchantInfo }}</template>
        </el-table-column>
        <el-table-column min-width="130px" label="店铺logo" align="center">
          <template slot-scope="scope">
            <el-image
              v-if="scope.row.merchantLogo"
              :src="scope.row.merchantLogo.split(',')[0]"
              style="width: 60px; height: 60px"
              :preview-src-list="scope.row.merchantLogo.split(',')"
            >
            </el-image>
          </template>
        </el-table-column>
        <el-table-column min-width="130px" label="环境照" align="center">
          <template slot-scope="scope">
            <el-image
              v-if="scope.row.merchantUrl"
              :src="scope.row.merchantUrl.split(',')[0]"
              style="width: 60px; height: 60px"
              :preview-src-list="scope.row.merchantUrl.split(',')"
            >
            </el-image>
          </template>
        </el-table-column>
        <el-table-column min-width="130px" label="营业执照图片" align="center">
          <template slot-scope="scope">
            <el-image
              v-if="scope.row.licenseImgUrl"
              :src="scope.row.licenseImgUrl.split(',')[0]"
              style="width: 60px; height: 60px"
              :preview-src-list="scope.row.licenseImgUrl.split(',')"
            >
            </el-image>
          </template>
        </el-table-column>
        <el-table-column label="状态" align="center" show-overflow-tooltip>
          <template slot-scope="{ row }">
            <el-tag v-if="row.status == 0" type="info">审核中</el-tag>
            <el-tag v-if="row.status == 1" type="succcess">已通过</el-tag>
            <el-tag v-if="row.status == 2" type="danger">未通过</el-tag>
          </template>
        </el-table-column>
        <!-- <el-table-column label="轮播图链接" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.bannerUrl }}</template>
          </el-table-column> -->
        <el-table-column label="用户名称" align="center" show-overflow-tooltip>
          <template slot-scope="scope">{{ scope.row.memberName }}</template>
        </el-table-column>
        <!-- <el-table-column label="官网地址" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.official }}</template>
          </el-table-column> -->
        <!-- <el-table-column label="身份证正面" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.idCardFront }}</template>
          </el-table-column>
          <el-table-column label="身份证反面" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.idCardBack }}</template>
          </el-table-column>
          <el-table-column label="身份证号" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.idCardNo }}</template>
          </el-table-column> -->
        <el-table-column label="拒绝原因" align="center" show-overflow-tooltip>
          <template slot-scope="scope">{{ scope.row.refuse }}</template>
        </el-table-column>
        <!-- <el-table-column label="类型" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.type }}</template>
          </el-table-column> -->
        <el-table-column
          min-width="150px"
          label="创建时间"
          align="center"
          show-overflow-tooltip
        >
          <template slot-scope="scope">{{ scope.row.createDt }}</template>
        </el-table-column>
        <el-table-column
          min-width="150px"
          label="修改时间"
          align="center"
          show-overflow-tooltip
        >
          <template slot-scope="scope">{{ scope.row.updateDt }}</template>
        </el-table-column>
        <el-table-column label="省名称" align="center" show-overflow-tooltip>
          <template slot-scope="scope">{{ scope.row.provinceName }}</template>
        </el-table-column>
        <el-table-column label="市名称" align="center" show-overflow-tooltip>
          <template slot-scope="scope">{{ scope.row.cityName }}</template>
        </el-table-column>
        <el-table-column label="区县名称" align="center" show-overflow-tooltip>
          <template slot-scope="scope">{{ scope.row.areaName }}</template>
        </el-table-column>
        <el-table-column label="几星" align="center" show-overflow-tooltip>
          <template slot-scope="scope">{{ scope.row.star }}</template>
        </el-table-column>
        <el-table-column label="操作" fixed="right" width="135" align="center">
          <template slot-scope="scope">
            <!-- <el-button type="text" v-if="scope.row.status==0" @click="review(scope.row)" size="small">审核</el-button>
            <el-button type="text"  @click="edit(scope.row)" size="small">编辑</el-button> -->
            <span class="edit" @click="edit(scope.row)">编辑</span>
            <span
              class="review"
              v-if="scope.row.status == 0"
              @click="review(scope.row)"
              >审核</span
            >
            <el-popconfirm
              title="确定删除吗？"
              @confirm="handleDelte(scope.row.id)"
            >
              <el-button slot="reference" type="text">删除</el-button>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <div class="block">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="queryInfo.currPage"
          :page-sizes="[20, 50, 100, 200]"
          :page-size="queryInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>

    <el-dialog title="审核" :visible.sync="showDialog" width="520px">
      <el-form ref="form" label-width="120px">
        <el-form-item label="审核意见:">
          <el-select v-model="form.status">
            <el-option value="1" label="通过"></el-option>
            <el-option value="2" label="不通过"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="form.status == 2" label="拒绝原因:">
          <el-input type="textarea" autosize v-model="form.refuse"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button size="small" @click="showDialog = false">取消</el-button>
        <el-button size="small" type="primary" @click="handleSave"
          >确定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      :title="title"
      v-dialogDrag
      :visible.sync="dialogVisible"
      width="1000px"
    >
      <el-row>
        <el-form label-width="100">
          <el-col :span="24">
            <el-form-item key="1" label="用户类型:">
              <el-input
                style="width: 300px"
                readonly
                v-model="form.memberName"
              ></el-input>
              <el-button
                style="margin-left: 12px"
                @click="choseMember"
                type="primary"
                >选择</el-button
              >
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item key="2" label="开店主体:">
              <el-radio-group v-model="form.type">
                <el-radio label="1">企业</el-radio>
                <el-radio label="2">个体</el-radio>
                <el-radio label="3">个人</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item key="3" label="店铺名称:">
              <el-input
                v-model="form.merchantName"
                style="width: 333px"
                placeholder="店铺名称"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item key="4" label="提交人姓名:">
              <el-input
                v-model="form.linkMan"
                style="width: 333px"
                placeholder="提交人姓名"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item key="5" label="联系方式:">
              <el-input
                v-model="form.phone"
                style="width: 333px"
                placeholder="联系方式"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item key="6" label-width="100" label="店铺简介:">
              <el-input
                v-model="form.merchantInfo"
                type="textarea"
                style="width: calc(100% - 143px)"
                :rows="3"
                placeholder="店铺简介"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item key="7" label="店铺环境:">
              <el-upload
                accept=".jpg,.jpeg,.png,.gif,.bmp,.pdf,.JPG,.JPEG,.PBG,.GIF,.BMP,.PDF"
                :on-success="handleSuccess1"
                :action="$uploadURL"
                :show-file-list="false"
                :on-preview="handlePictureCardPreview"
                :on-remove="handleRemove1"
                class="avatar-uploader"
              >
                <div
                  v-if="form.merchantUrl && form.merchantUrl !== ''"
                  class="flex"
                >
                  <div style="margin: 5px" class="imgBox">
                    <img :src="form.merchantUrl" class="avatar" alt="" />
                    <div class="img-icon">
                      <i
                        class="el-icon-zoom-in"
                        @click.stop="openElImageViewer(form.merchantUrl)"
                      ></i>
                      <i
                        class="el-icon-delete"
                        @click.stop="
                          handelRemove(form.merchantUrl, 'merchantUrl')
                        "
                      ></i>
                    </div>
                  </div>
                </div>
                <i v-else class="el-icon-plus"></i>
              </el-upload>
              <el-dialog append-to-body :visible.sync="dialogVisibleImg">
                <img width="100%" :src="dialogImageUrl" alt="" />
              </el-dialog>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item key="8" v-if="form.type != 3" label="营业执照:">
              <el-upload
                accept=".jpg,.jpeg,.png,.gif,.bmp,.pdf,.JPG,.JPEG,.PBG,.GIF,.BMP,.PDF"

                :action="$uploadURL"
                :on-success="handleSuccess2"
                :show-file-list="false"
                :on-preview="handlePictureCardPreview"
                :on-remove="handleRemove2"
                class="avatar-uploader"
              >
                <div v-if="form.licenseImgUrl" class="flex">
                  <div style="margin: 5px" class="imgBox">
                    <img :src="form.licenseImgUrl" class="avatar" alt="" />
                    <div class="img-icon">
                      <i
                        class="el-icon-zoom-in"
                        @click.stop="openElImageViewer(form.licenseImgUrl)"
                      ></i>
                      <i
                        class="el-icon-delete"
                        @click.stop="
                          handelRemove(form.licenseImgUrl, 'licenseImgUrl')
                        "
                      ></i>
                    </div>
                  </div>
                </div>
                <i v-else class="el-icon-plus"></i>
              </el-upload>
              <el-dialog append-to-body :visible.sync="dialogVisibleImg">
                <img width="100%" :src="dialogImageUrl" alt="" />
              </el-dialog>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item key="9" label="店铺logo:">
              <el-upload
                accept=".jpg,.jpeg,.png,.gif,.bmp,.pdf,.JPG,.JPEG,.PBG,.GIF,.BMP,.PDF,.wepb"
                :action="$uploadURL"
                :on-success="handleSuccess3"
                :show-file-list="false"
                :on-preview="handlePictureCardPreview"
                :on-remove="handleRemove3"
                class="avatar-uploader"
              >
                <div v-if="form.merchantLogo" class="flex">
                  <div style="margin: 5px" class="imgBox">
                    <img :src="form.merchantLogo" class="avatar" alt="" />
                    <div class="img-icon">
                      <i
                        class="el-icon-zoom-in"
                        @click.stop="openElImageViewer(form.merchantLogo)"
                      ></i>
                      <i
                        class="el-icon-delete"
                        @click.stop="
                          handelRemove(form.merchantLogo, 'merchantLogo')
                        "
                      ></i>
                    </div>
                  </div>
                </div>
                <i v-else class="el-icon-plus"></i>
              </el-upload>
              <el-dialog append-to-body :visible.sync="dialogVisibleImg">
                <img width="100%" :src="dialogImageUrl" alt="" />
              </el-dialog>
            </el-form-item>
          </el-col>
        </el-form>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" :loading="formBtnLoading" @click="submitForm"
          >确 定</el-button
        >
      </span>
      <el-dialog
        width="940px"
        title="选择用户"
        v-dialogDrag
        append-to-body
        :visible.sync="dialogVisibleTable"
      >
        <el-form :inline="true">
          <el-form-item label="用户名：">
            <el-input
              clearable
              v-model="innerQueryInfo.condition.memberName"
              placeholder="用户名"
            ></el-input>
          </el-form-item>
          <el-form-item label="账号">
            <el-input
              clearable
              v-model="innerQueryInfo.condition.loginName"
              placeholder="账号"
            ></el-input>
          </el-form-item>
          <el-form-item label="手机号:">
            <el-input
              clearable
              v-model="innerQueryInfo.condition.phone"
              placeholder="手机号"
            ></el-input>
          </el-form-item>
          <el-button type="primary" @click="getInnerTable">搜索</el-button>
        </el-form>
        <el-table :data="innerTable" border height="60vh" style="width: 100%">
          <el-table-column
            type="index"
            align="center"
            label="序号"
          ></el-table-column>
          <el-table-column
            prop="memberName"
            align="center"
            label="用户名"
          ></el-table-column>
          <el-table-column prop="loginName" align="center" label="登录账号">
          </el-table-column>
          <el-table-column prop="phone" align="center" label="手机号">
          </el-table-column>
          <el-table-column label="操作" align="center">
            <template slot-scope="{ row }">
              <el-button @click="innerBtn(row)" type="primary">选择</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          @size-change="handleSizeChangeInner"
          @current-change="handleCurrentChangeInner"
          :current-page="innerQueryInfo.currPage"
          :page-sizes="[100, 200, 300, 400]"
          :page-size="innerQueryInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="innerTotal"
        >
        </el-pagination>
      </el-dialog>
    </el-dialog>

    <ElImageViewer
      v-if="showElImageViewer"
      :zIndex="8000"
      :src="Elimgs"
      :on-close="handleCloseElImageViewer"
      :url-list="[Elimgs]"
    ></ElImageViewer>
  </div>
</template>

<script>
import drag from "../../components/drag";
import ElImageViewer from "element-ui/packages/image/src/image-viewer";
export default {
  components: { ElImageViewer },
  data() {
    return {
      formBtnLoading: false,
      merchantLogoList: [],
      merchantUrlList: [],
      licenseImgUrlList: [],
      innerTotal: 0,
      innerTable: [],
      innerQueryInfo: {
        pageSize: 100,
        currPage: 1,
        condition: {
          memberName: "",
          loginName: "",
          phone: "",
          merchantInfo: "",
        },
      },
      dialogVisibleTable: false,
      dialogImageUrl: "",
      dialogVisibleImg: false,
      form: {
        memberName: "",
        memberCode: "",
        type: "1",
        merchantName: "",
        linkMan: "",
        phone: "",
        merchantUrl: "",
        licenseImgUrl: "",
        merchantLogo: "",
        merchantInfo:""
      },
      dialogVisible: false,
      labelType: "add",
      rules: {},
      total: 0,
      queryInfo: {
        pageSize: 20,
        pageCount: 1,
        currPage: 1,
        condition: {
          merchantName: "",
          phone: "",
        },
      },
      showDialog: false,
      tableData: [],
      title: "", // 新增编辑标题
      Elimgs: "",
      showElImageViewer: false,
    };
  },

  created() {
    this.getList();
  },
  methods: {

    // exceed() {
    //   this.$message.info("只可上传一张图片");
    // },
    async submitForm() {
      let data = JSON.parse(JSON.stringify(this.form));
      // data.merchantLogo = this.merchantLogoList.join(",");
      // data.merchantUrl = this.merchantUrlList.join(",");
      // data.licenseImgUrl = this.licenseImgUrlList.join(",");
      data.merchantLogo = this.form.merchantLogo;
      data.merchantUrl = this.form.merchantUrl;
      data.licenseImgUrl = this.form.licenseImgUrl;

      this.formBtnLoading = true;
      let url = "";
      this.title === "新增"
        ? (url = "merchant/addMerchantInAdmin")
        : (url = "merchant/update");
      let res = await this.$http.post(url, data);
         this.formBtnLoading = false;
      if (res.data.code == 200) {
        this.$notify({
          title: "成功",
          message: "保存成功",
          type: "success",
        });

      this.dialogVisible = false;
        this.getList();
        this.clear()
      } else {
        this.$message.error(res.data.message);
      }
    },
    clear(){
        this.form.licenseImgUrl = "";
        this.form.merchantUrl = "";
        this.form.merchantLogo = "";
        this.form.memberName = "";
        this.form.memberCode = "";
        this.form.merchantName = "";
        this.form.linkMan = ""
        this.form.phone = ""
        this.form.merchantInfo = ""
    },
    innerBtn(row) {
      let { memberCode, memberName } = row;
      this.form.memberCode = memberCode;
      this.form.memberName = memberName;
      this.dialogVisibleTable = false;
    },
    handleSuccess1(res, file) {
      if (res.code == 200) {
        this.merchantUrlList.push(res.data.url);
        this.form.merchantUrl = res.data.url;
        console.log(this.form, 587);
      } else {
        this.$message.error(res.message);
      }
    },
    handleSuccess2(res, file) {
      if (res.code == 200) {
        this.licenseImgUrlList.push(res.data.url);
        this.form.licenseImgUrl = res.data.url;
      } else {
        this.$message.error(res.message);
      }
    },
    handleSuccess3(res, file) {
      if (res.code == 200) {
        this.merchantLogoList.push(res.data.url);
        this.form.merchantLogo = res.data.url;
      } else {
        this.$message.error(res.message);
      }
    },
    // 查看
    openElImageViewer(item) {
      this.Elimgs = item;
      this.showElImageViewer = true;
    },
    // 关闭
    handleCloseElImageViewer() {
      this.showElImageViewer = false;
    },
    //删除
    handelRemove(item, type) {
      if (type === "merchantLogo") {
        return (this.form.merchantLogo = "");
      }
      if (type === "merchantUrl") {
        return (this.form.merchantUrl = "");
      }
      if (type === "licenseImgUrl") {
        return (this.form.licenseImgUrl = "");
      }
    },

    choseMember() {
      this.dialogVisibleTable = true;
      this.getInnerTable();
    },
    async getInnerTable() {
      let res = await this.$http.post(
        "member/listByCreateMerchant",
        this.innerQueryInfo
      );
      console.log(res, "ss");
      if (res.data.code == 200) {
        this.innerTotal = res.data.data.count;
        this.innerTable = res.data.data.data;
      } else {
        this.$message.error(res.data.message);
      }
    },
    handleRemove1(file, fileList) {

      let url = file.response.data.url;
      let index = this.merchantUrlList.findIndex((item) => {
        return item == url;
      });
      if (index != -1) {
        this.merchantUrlList.splice(index, 1);
      }
    },
    handleRemove2(file, fileList) {
      let url = file.response.data.url;
      let index = this.licenseImgUrlList.findIndex((item) => {
        return item == url;
      });
      if (index != -1) {
        this.licenseImgUrlList.splice(index, 1);
      }
    },
    handleRemove3(file, fileList) {
      let url = file.response.data.url;
      let index = this.merchantLogoList.findIndex((item) => {
        return item == url;
      });
      if (index != -1) {
        this.merchantLogoList.splice(index, 1);
      }
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisibleImg = true;
    },
    addTableData() {
      this.title = "新增";
      this.clear()
      this.dialogVisible = true;
    },
    //获取数据
    getList() {
      var that = this;
      that.$http
        .post("/merchant/list", that.queryInfo)
        .then(function (response) {
          console.log(response.data.data);
          if (response.data.code == 200) {
            that.total = response.data.data.count;
            that.queryInfo.currPage = response.data.data.currPage;
            that.tableData = response.data.data.data;
          }
        });
    },

    // 保存
    handleSave() {
      var that = this;
      that.$http
        .post("/merchant/save", that.form)
        .then(function (response) {
          if (response.data.code == 200) {
            that.$notify.success({
              title: "提示",
              message: "保存成功",
              showClose: true,
            });
            that.showDialog = false;
            that.getList();
          } else {
            that.$notify.info({
              title: "提示",
              message: response.data.message,
              showClose: true,
            });
          }
        })
        .catch(function (error) {});
    },

    //审核
    review(row) {
      this.showDialog = true;
      this.labelType = "edit";
      if (row.status == 0) {
        this.form.status == "1";
      } else {
        this.form.status = row.status.toString();
      }

      this.form.id = row.id;
      // this.form = JSON.parse(JSON.stringify(row))
    },

    //修改
    async edit(row) {
      this.title = "修改";
     this.clear()
      this.dialogVisible = true;
      const res = await this.$http.post("/merchant/queryOne", { id: row.id });
      if (res.data.code === 200) {
        this.form = res.data.data;
      }
      console.log(res, 644);
    },

    // 删除用户
    async handleDelte(id) {
      let res = await this.$http.post("/merchant/delete", { id });
      if (res.data.code == 200) {
        this.$notify.success({
          title: "提示",
          message: "删除成功",
          showClose: true,
        });
        this.getList();
      } else {
        this.$notify.info({
          title: "提示",
          message: res.data.message,
          showClose: true,
        });
      }
    },

    //新增按钮
    newBtn() {
      this.labelType = "add";
      this.showDialog = true;
      this.form = {
        id: "",
        status: "",
        refuse: "",
      };
    },

    // 修改页数大小
    handleSizeChange(val) {
      this.queryInfo.pageSize = val;
      this.getList();
    },

    // 获取当前页面
    handleCurrentChange(val) {
      this.queryInfo.currPage = val;
      this.getList();
    },
    handleSizeChangeInner(val) {
      this.innerQueryInfo.pageSize = val;
      this.getInnerTable();
    },

    // 获取当前页面
    handleCurrentChangeInner(val) {
      this.innerQueryInfo.currPage = val;
      this.getInnerTable();
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/.el-table__body-wrapper::-webkit-scrollbar-thumb {
  background: #409eff;
  cursor: pointer;
}
/deep/.el-table__fixed {
  height: calc(100% - 8px) !important;
}
/deep/.el-table__fixed-right {
  height: calc(100% - 8px) !important;
}
/deep/ .el-upload--picture-card {
  height: 110px;
  width: 110px;
  line-height: 110px;
}
/deep/ .el-upload-list--picture-card .el-upload-list__item {
  width: 110px;
  height: 110px;
}

.edit {
  color: brown;
  margin-left: 20px;
  cursor: pointer;
}

.review {
  color: orange;
  margin-left: 15px;
  cursor: pointer;
}

.img-icon {
  width: 100px;
  height: 100px;
  border-radius: 6px;
  position: absolute;
  left: 0;
  top: 0;
  display: none;
  align-items: center;
  justify-content: center;
  gap: 10px;
  background-color: rgba(0, 0, 0, 0.4);

  i {
    font-size: 26px;
    color: #fff;
  }
}

.avatar-uploader {
  width: 100px;
  height: 100px;
  border: 1px solid #ccc;
  margin-left: 77px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.avatar-uploader ::v-deep .el-upload {
  //background-color: #fbfdff;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  border: 1px dashed #c0ccda;
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99;
}

/deep/.el-loading-spinner {
  margin-top: -74px;
}

.avatar {
  display: block;
  width: 100px;
  height: 100px;
  border-radius: 6px;
}

.imgBox {
  //width: 100px;
  height: 100px;
  position: relative;
  //margin-bottom: 5px;
}

.imgBox:hover .img-icon {
  display: flex;
}
</style>
